export const tableStyle = {
  margin: '5px 1%',
  width: '98%',
  clear: 'both',
  minHeight: 35,
  borderBottom: '1px solid #CCC',
}

export const Form = {
  paddingTop: 10,
}

export const NewTrackerLink = {
  backgroundColor: '#CCC',
  width: '95%',
  display: 'inline-block',
  padding: '2%',
}

export const tableHeaderStyle = {
  margin: '1%',
  width: '98%',
  clear: 'both',
  height: '100%',
  minHeight: 30,
  borderBottom: '1px solid #CCC',
  fontWeight: 'bold',
}

export const nonLinkColumn = {
  display: 'inline-block',
  verticalAlign: 'top',
  border: 0,
  width: 130,
  padding: 10,
}

export const linkColumn = {
  display: 'inline-block',
  border: 0,
  width: 600,
}
export const linkTable = {
  padding: 0,
  margin: 0,
}

export const linkTableTD = {
  padding: 0,
  margin: 0,
  border: 0,
}

export const linkTableTDLink = {
  minWidth: 420,
  padding: 0,
  border: 0,
}

export const linkTableTR = {
  minWidth: 130,
}

export const buttonColumn = {
  height: 40,
}

export const addLink = {
  width: 120,
  margin: '5px 10px',
}

export const userLinkStyle = {
  width: 130,
  height: 40,
  padding: '0 10px',
  WebkitAppearance: 'none',
  MozAppearance: 'none',
}

export const neverMindStyle = {
  padding: '0 10px',
  lineHeight: '40px',
}

const action = {
  verticalAlign: 'top',
  backgroundColor: '#f1f1f1',
  backgroundSize: '15px 15px',
  backgroundPosition: '50% 50%',
  backgroundRepeat: 'no-repeat',
  border: '1px solid #ccc',
  borderRadius: '3px',
  cursor: 'pointer',
  display: 'inline-block',
  height: 27,
  marginRight: 15,
  marginBottom: 2,
  width: 24,
}

export const actionLink = mediaUrl => ({
  ...action,
  marginRight: 0,
  backgroundImage: `url(${mediaUrl}images/eventmanager/clone.png)`,
})

export const actionDelete = mediaUrl => ({
  ...action,
  backgroundImage: `url(${mediaUrl}images/eventmanager/delete.png)`,
})

export const flex = {
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
}
